<template>
  <div class="wgcna-query-result">
    <a-spin :spinning="spinning" tip="Loading...">
      <SearchInput v-model="searchValue" :data-source="searchDataSource" @search="handleTopSearch" style="width: 100%;"
                   placeholder="Example:Key words eg. liver Project ID PRJNA eg.PRJNA167458" />

      <div class="wgcna-query-result-detail">
        <div class="wgcna-query-result-detail-item">
          <p>Bioproject</p>
          <a :href="'https://www.ncbi.nlm.nih.gov/bioproject/?term=' + this.wgcnaData.bioproject"
             target="_blank">{{ this.wgcnaData.bioproject }}</a>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Gse_acc</p>
          <a :href="'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=' + this.wgcnaData.gse_acc"
             target="_blank">{{ this.wgcnaData.gse_acc }}</a>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Citations</p>
          <div>{{ this.wgcnaData.citations }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Overall_design</p>
          <div>{{ this.wgcnaData.overall_design }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Study_title</p>
          <div>{{ this.wgcnaData.study_title }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Summary</p>
          <div>{{ this.wgcnaData.summary }}</div>
        </div>
      </div>

      <div class="wgcna-query-result-pdf-list">
        <div>
          <span>Module-Tarit correlation heatmap</span>

<!--          <span v-if="filePath.tiff === ''" style="display: inline-block;height: 100%;line-height: 100%;"> tiff文件较大，请稍候... </span>-->
          <img :src="filePath.tiff" alt="networkheatmap"/>
        </div>
        <div>
          <span>Genes Correlation heatmap</span>
<!--          <pdf :src="filePath.pdf" class="pdf"></pdf>-->
          <img :src="filePath.pdf" alt="networkheatmap"/>
        </div>
      </div>

      <template>
        <div class="wgcna-query-result-module-select">
          <div>
            <span>Choose module：</span>
            <a-select size="large" v-model="currentCategory" style="width: 200px" @change="handleCategoryChange">
              <a-select-option v-for="i in categories" :key="i.name">
                {{ i.name }}
              </a-select-option>
            </a-select>
          </div>

          <a-tag color="orange" @click="downloadFile(filePath.tsv)">
            <a-icon type="cloud-download"/>
            download
          </a-tag>
        </div>


        <a-table
            :columns="columns"
            size="middle"
            bordered :scroll="{ x: true }"
            :rowKey="(record,index)=>index"
            :pagination="pagination"
            :data-source="tableData">
          <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 0.5rem;"
          >
            <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`Search ${column.title}`"
                :value="selectedKeys[0]"
                size="small"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >Search
            </a-button>
            <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
            >Reset
            </a-button>
          </div>
          <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="customRender" slot-scope="text, record, index, column">
                <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>

        <div class="graph-group">
          <a-spin tip="Loading..." :spinning="chartLoading">
            <div class="graph-opera">
              <div>
                <span>Layout mode：</span>
                <a-select default-value="force" style="width: 140px" @change="graphLayoutChange">
                  <a-select-option value="none">
                    Fixed
                  </a-select-option>
                  <a-select-option value="force">
                    Force-oriented
                  </a-select-option>
                  <a-select-option value="circular">
                    Circular
                  </a-select-option>
                </a-select>
              </div>

              <div style="margin-left: 1rem">
                <span>Relation quantity：</span>
                <a-slider v-model="slider" :min="0" :max="1" :step="0.01" @afterChange="changeChart"/>
              </div>

              <div class="graph-select">
                <span>Search：</span>
                <a-auto-complete
                    :data-source="dataSource"
                    placeholder="Search for genes or module names"
                    @search="searchGraph" option-label-prop="value"
                    style="width: 100%"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="node in dataSource" :key="node.id"
                                     @click="selectGraph(node)"
                                     :value="node.node">
                      {{ node.name }}
                    </a-select-option>
                  </template>

                  <a-input>
                    <a-icon slot="suffix" type="search" class="certain-category-icon"/>
                  </a-input>
                </a-auto-complete>
              </div>
            </div>

            <Chart echarts-id="WGCNA-relation-graph" :height="chartHeight" :width="chartWidth"
                   ref="graphChart"
                   :option="WGCNARelationOption"/>
          </a-spin>

          <a-spin tip="Loading..." :spinning="chartLoading">
            <a-tabs default-active-key="0">
              <a-tab-pane v-if="imgList['GO-BP']" key="0" tab="GO-BP">
                <img :src="imgList['GO-BP']" class="bioproject-img" alt="GO-BP">
              </a-tab-pane>
              <a-tab-pane v-if="imgList['GO-CC']" key="1" tab="GO-CC">
                <img :src="imgList['GO-CC']" class="bioproject-img" alt="GO-CC">
              </a-tab-pane>
              <a-tab-pane v-if="imgList['GO-MF']" key="2" tab="GO-MF">
                <img :src="imgList['GO-MF']" class="bioproject-img" alt="GO-MF">
              </a-tab-pane>
              <a-tab-pane v-if="imgList['KEGG']" key="3" tab="KEGG">
                <img :src="imgList['KEGG']" class="bioproject-img" alt="KEGG">
              </a-tab-pane>
            </a-tabs>
          </a-spin>
        </div>
      </template>
    </a-spin>
  </div>
</template>

<script>
import Chart from "@/components/Chart.vue";
import WGCNARelation from "@/chartsOptions/WGCNARelation";
import {parseCsv,downloadFile} from "@/utils/csv.js"
import pdf from 'vue-pdf';
import {project_list, wgcna_get_files} from "@/request/api.js"
import bus from '@/utils/bus';
import Tiff from 'tiff.js';
import SearchInput from "@/components/SearchInput/index.vue";

let chartLinkData = [];
let nodesDatas = [];

export default {
  name: "queryResultNew",
  components: {
    SearchInput,
    pdf, Chart
  },
  data() {
    return {
      searchValue:"",
      searchDataSource:[],
      wgcnaData: {},
      categories: [],
      currentCategory: "",
      slider: 0.7,
      imgList: {},

      spinning: false,
      chartLoading: false,
      bioproject: "",
      filePath: {
        tsv: "",
        pdf: "",
        tiff: ""
      },
      columns: [],
      tableData: [],
      nodesColumns: [],
      pagination: {
        pageSize: 10,
        showSizeChanger: true,
        showTotal(total) {
          return `Total ${total} items`
        },
      },
      /* 表格搜索 */
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      /* 表格搜索 End */
      WGCNARelationOption: null,
      chartHeight: "60vh",
      chartWidth: "100%",
      dataSource: [],
      searchSelectNode: null,
      pdfSrc: ""
    }
  },
  created() {
    this.bioproject = this.$route.query.bioproject;
    this.wgcnaData = JSON.parse(window.localStorage.getItem('current_wgcna_data'));

    const source_url = this.common.source_url + `bged2-rnaseq-wgcna/${this.bioproject}/`;

    wgcna_get_files({id: this.bioproject}).then(res => {
      this.categories = res.modules.map(item => ({name: item}));
      this.currentCategory = this.categories[0].name;
      this.filePath = {
        tsv: `${source_url}/${this.currentCategory}/${this.currentCategory}.tsv`,
        pdf: `${source_url}/TOM.png`,
        tiff: `${source_url}/correlation.png`,
      }

      // const xhr = new XMLHttpRequest();
      // xhr.responseType = 'arraybuffer';
      // xhr.open('GET', source_url + res.tiff);
      // xhr.onload = () => {
      //   Tiff.initialize({TOTAL_MEMORY: 100 * 1024 * 1024});
      //   const tiff = new Tiff({buffer: xhr.response});
      //   const canvas = tiff.toCanvas();
      //   this.filePath.tiff = canvas.toDataURL();
      // };
      // xhr.send();

      this.imgList["GO-BP"] = `${source_url}/${this.currentCategory}/function_enrichment/GO_BP.enrichment.png`;
      this.imgList["GO-CC"] = `${source_url}/${this.currentCategory}/function_enrichment/GO_CC.enrichment.png`;
      this.imgList["GO-MF"] = `${source_url}/${this.currentCategory}/function_enrichment/GO_MF.enrichment.png`;
      this.imgList["KEGG"] = `${source_url}/${this.currentCategory}/function_enrichment/KEGG.enrichment.png`;

      this.txtLoad();
    })

    project_list({
      "search": "",
      "page": 1,
      "limit": 1000,
      "sort": "bioproject",
      "order": "asc"
    }).then(res => {
      if (res.code === 0) {
        this.searchDataSource = res.data.data.map(item => ({
          ...item,
          key:item.bioproject,
          value:item.bioproject
        }));
      } else {
        this.$message.error(res.errMsg)
      }
    })
  },
  methods: {
    downloadFile,
    handleTopSearch(){
      this.$router.push({path:"/WGCNA",query:{search:this.searchValue}})
    },
    handleCategoryChange() {
      const source_url = this.common.source_url + `bged2-rnaseq-wgcna/${this.bioproject}/`;
      this.filePath.tsv = `${source_url}/${this.currentCategory}/${this.currentCategory}.tsv`;

      this.imgList["GO-BP"] = `${source_url}/${this.currentCategory}/function_enrichment/GO_BP.enrichment.png`;
      this.imgList["GO-CC"] = `${source_url}/${this.currentCategory}/function_enrichment/GO_CC.enrichment.png`;
      this.imgList["GO-MF"] = `${source_url}/${this.currentCategory}/function_enrichment/GO_MF.enrichment.png`;
      this.imgList["KEGG"] = `${source_url}/${this.currentCategory}/function_enrichment/KEGG.enrichment.png`;

      this.txtLoad();
    },
    //表格搜索
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    //重置搜索
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    txtLoad() {
      parseCsv(this.filePath.tsv, (csv) => {
        csv.sort((a, b) => b.combined_score - a.combined_score)

        this.columns = [];
        Object.keys(csv[0]).forEach(item => {
          let obj = {
            title: item,
            dataIndex: item,
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender'
            },
            onFilter: (value, record) => record[item].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus();
                });
              }
            }
          }
          this.columns.push(obj)
        })

        this.tableData = csv;

        this.generateChart();
      })
    },
    generateChart() {
      chartLinkData = [];
      nodesDatas = [];

      this.tableData.forEach((node, idx) => {
        if(nodesDatas.findIndex(item => item.name === node["#node1"]) !== -1) return;

        nodesDatas.push({
          category: this.currentCategory,
          id: idx + "",
          name: node["#node1"],
          altName: node["#node1"],
          node: node["#node1"],
        })
      })

      const xNum = Math.ceil(Math.sqrt(nodesDatas.length));
      const yNum = Math.ceil(nodesDatas.length / xNum);
      const CanvasWidth = document.getElementById("WGCNA-relation-graph").offsetWidth;
      const CanvasHeight = document.getElementById("WGCNA-relation-graph").offsetHeight;
      //x,y轴上的间隔
      const xGap = Math.floor(CanvasWidth / xNum);
      const yGap = Math.floor(CanvasHeight / yNum)

      const getXY = (nodeData, index) => {
        //Math.ceil(index / xNum)获取在第几行
        nodeData.x = (index % xNum) * xGap;
        nodeData.y = Math.ceil(index / xNum) * yGap;

        return nodeData;
      }

      nodesDatas = nodesDatas.map((node,index) => {
         return getXY(node, index + 1);
      })

      this.tableData.forEach((node) => {
        let fromNodeIndex = -1;
        let toNodeIndex = -1;

        nodesDatas.forEach((item) => {
          if (item.name === node["#node1"]) {
            fromNodeIndex = item.id;
          }
          if (item.name === node["node2"]) {
            toNodeIndex = item.id;
          }
        })
        if (fromNodeIndex === -1 || toNodeIndex === -1) return;

        chartLinkData.push({
          source: fromNodeIndex,
          sourceNode: node["#node1"],
          combined_score: node["combined_score"],
          target: toNodeIndex,
          targetNode: node["node2"],
        })
      })

      this.changeChart(true);
    },
    changeChart(signEvent = false) {
      this.spinning = true;

      //删除原先选中的节点的额外属性
      if (this.searchSelectNode !== null) {
        delete this.searchSelectNode.symbolSize;
        delete this.searchSelectNode.label;
        this.searchSelectNode = null;
      }

      let links = [];
      chartLinkData.forEach(item => {
        (item.combined_score <= this.slider) && links.push(item);
      })

      // WGCNARelation.series[0].data = nodesDatas;
      // 20240609: 去除没有连线的点
      WGCNARelation.series[0].data = nodesDatas.filter(item => {
        const iii = links.findIndex(te => item.id == te.source || item.id == te.target);
        return !(iii === -1)
      });
      WGCNARelation.series[0].categories = [] //JSON.parse(JSON.stringify(this.categories));
      WGCNARelation.series[0].links = links;
      WGCNARelation.color = [JSON.parse(JSON.stringify(this.currentCategory))];
      WGCNARelation.toolbox.feature.myDownload = {
        show: true,
        title: "下载",
        icon: 'image:///images/echarts-toolbox-download.png',
        onclick: () => {
          this.echartsExportImg();
        }
      };

      this.WGCNARelationOption = WGCNARelation;

      this.spinning = false;

      if (signEvent) {
        this.$refs.graphChart.onChartClick((param) => {
          if (param.dataType !== 'node') return;

          this.setSelectNodeData(param.data.node)
          this.linksChange(param.data);
        })
      }
    },
    linksChange() {
      this.spinning = true;

      let links = [];
      chartLinkData.forEach(item => {
        item.combined_score <= this.slider && links.push(item);
      })
      this.WGCNARelationOption.series[0].links = links;

      this.spinning = false;
    },
    echartsExportImg() {
      bus.$emit("globalLoading", {bool: true, tip: "正在下载中..."});

      const convertBase64UrlToBlob = (base64) => {
        const parts = base64.dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; i++) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
      };

      const myChart = this.$echarts.getInstanceByDom(
          document.getElementById("WGCNA-relation-graph")
      );

      this.chartHeight = "450vh";
      this.chartWidth = "450vw";
      myChart.setOption({
        series: [
          {
            // label: {show: true},
            force: {
              repulsion: 10,//斥力因子
            }
          }
        ]
      })
      myChart.resize();

      setTimeout(() => {
        //下载结束时执行
        const close = () => {
          this.chartHeight = "60vh";
          this.chartWidth = "100%";

          setTimeout(() => {
            myChart.setOption({
              series: [
                {
                  // label: {show: false},
                  force: {
                    edgeLength: [20, 100],
                    repulsion: 100,//斥力因子。该值越大则节点之间的距离越远。
                    gravity: 0.2 //节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
                  }
                }
              ]
            })
            myChart.resize();
            bus.$emit("globalLoading", {bool: false, tip: "正在下载中..."})
          }, 1500)
        }

        myChart.resize();
        const url = myChart.getConnectedDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff", // 图表背景色
          excludeComponents: [// 保存图表时忽略的工具组件,默认忽略工具栏
            'toolbox'
          ],
          type: "png", // 图片类型支持png和jpeg
        });
        const a = document.createElement('a');
        a.download = `WGCNA-relation-graph.png`;
        a.target = '_blank';
        a.href = url;
        // Chrome and Firefox
        if (typeof MouseEvent === 'function') {
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          a.dispatchEvent(evt);
          close();
        } else {
          // IE
          const base64 = {
            dataURL: url,
            type: 'png',
            ext: 'png',
          };
          const blob = convertBase64UrlToBlob(base64);
          // 下载
          window.navigator.msSaveBlob(blob, `WGCNA-relation-graph.png`);
          close();
        }
      }, 1500)
    },
    searchGraph(searchText) {
      let dataSource = []
      nodesDatas.forEach(item => {
        if (item.name.indexOf(searchText) !== -1 && !dataSource.includes(item)) {
          dataSource.push(item)
        }
      })
      this.dataSource = dataSource;
    },
    selectGraph(val) {
      this.setSelectNodeData(val.node)

      this.linksChange(val);
    },
    setSelectNodeData(selectNode) {
      let selectNodeIndex = this.WGCNARelationOption.series[0].data.findIndex(item => item.node === selectNode);
      //删除原先选中的节点的额外属性
      if (this.searchSelectNode !== null) {
        delete this.searchSelectNode.symbolSize;
        delete this.searchSelectNode.label;
      }

      this.WGCNARelationOption.series[0].data[selectNodeIndex].symbolSize = 40;
      this.WGCNARelationOption.series[0].data[selectNodeIndex].label = {
        show: true,
        position: "inside",
        formatter: '{b}'
      }

      //存储当前选择的节点
      this.searchSelectNode = this.WGCNARelationOption.series[0].data[selectNodeIndex];
    },
    //改变布局方式
    graphLayoutChange(value) {
      this.WGCNARelationOption.series[0].layout = value;
    },
  },
  beforeDestroy() {
    bus.$off("echartInitFinish");
  }
}
</script>

<style scoped lang="scss">
.wgcna-query-result {
  background: #fff;
  width: 80rem;
  margin: 1.8rem 8rem;
  padding: 2rem;

  &-detail {
    margin-top: 1rem;

    &-item {
      margin-bottom: 2rem;

      p {
        margin-bottom: 0 !important;
        height: 37px;
        font-size: 21px;
        font-weight: 600;
        color: #333333;
        line-height: 37px;
      }

      div, a {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 37px;
      }
    }
  }

  &-pdf-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    div {
      width: 37rem;
      border-radius: 13px;
      border: 1px solid #E6E6E6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        height: 37px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
        display: inline-block;
        margin: 1rem 0;
      }

      .pdf, img {
        width: 25rem;
        height: 30rem;
      }
    }
  }

  &-module-select {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-tag {
      cursor: pointer;
      height: 33px;
      line-height: 33px;
      font-size: 16px;
    }

    &>div>span {
      height: 23px;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      line-height: 19px;
    }
  }
}

.graph-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  & > div {
    width: 36rem;
    height: calc(60vh + 0.5rem + 120px);
    padding: 1rem 2rem;
    border-radius: 13px;
    border: 1px solid #E6E6E6;
    overflow: hidden;
  }

  .graph-opera {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > div {
      display: flex;
      align-items: center;

      & > span {
        //max-width: 120px;
      }

      ::v-deep .ant-slider {
        width: 9rem;
      }
    }
  }

  .graph-select {
    width: 300px;
    margin: 0.5rem 0 20px 0;
  }
}

.bioproject-img {
  width: 100%;
}
</style>